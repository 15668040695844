.navUl {
  list-style: none;
  display: flex;
  justify-content: center;
  background-color: #6a040f;
  width: 100%;
}

.navUl a {
  color: white;
  text-decoration: none;
  margin: 0px 25px;
}

.navLarge {
  display: flex;
}

.hamburger {
  display: none;
  cursor: pointer;
}

@media (max-width: 900px) {
.navLarge {
  display: block;
}
.headerContainer{
  /* position: relative; */
}
  
}
