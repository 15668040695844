html {
  background-color: #f2e7bf;
}

#root {
  background-color: #f2e7bf;
}

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.accueil__core {
  margin-top: 20%;
}
.core {
  margin-top: 11%;
}

.header {
  color: white;
  background-color: #6a040f;
  padding: 5px;
  display: flex;
  justify-content: space-around;
}

.bloc {
  border-radius: 10px;
  background-color: white;
  padding: 15px;
  font-size: 18px;
}

.titre {
  padding: 15px;
  text-align: center;
  font-size: 28px;
}

.btn-warning {
  background-color: #6a040f !important;
  color: white !important;
  border-color: #6a040f !important;
}

.countdown {
  display: flex;
  justify-content: center;
}

.footer {
  color: white;
  background-color: #6a040f;
  padding: 5px;
  text-align: center;
  width: 100%;
  height: 75px;
}

.footer ul {
  list-style: none;
}

.feed {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 18%;
  right: 3%;
}

.imgAside {
  float: left;
  height: 300px;
  padding-right: 25px;
}

.endPara {
  padding-top: 50px;
}

.coreCQ {
  font-size: 22px;
}

.imgLeft {
  float: left;
  height: 250px;
  padding-right: 25px;
}
.imgRight {
  float: right;
  height: 250px;
}

.corePot {
  font-size: 25px;
  margin: 20px;
  padding: 15px;
}

.legende {
  font-style: italic;
  font-size: 14px;
  padding-left: 1%;
}

.imgBloc {
  display: flex;
  justify-content: center;
}

.imgBloc img {
  height: 1000px;
}
.politesse {
  padding-left: 15%;
}

.titreMarie {
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
  padding-top: 1%;
}

.signature {
  padding-left: 15%;
  font-size: 20px;
}

.imgLettremarie {
  display: flex;
  justify-content: flex-end;
  padding-right: 20%;
}

.auteur {
  text-align: end;
  font-style: italic;
}

.bold {
  font-weight: 700;
}

.titreStocquarts {
  font-size: 21px;
  padding: 1% 0%;
  text-align: center;
}

.video {
  display: flex;
  justify-content: center;
  padding: 2% 0%;
}

.bloc__photo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.photo {
  width: 300px;
  height: 200px;
  padding: 2%;
  object-fit: cover;
  border-radius: 10%;
  margin: 2%;
}

.navBloc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.p-3 {
  margin-bottom: 0.5rem;
  width: 50rem;
}

/* .btnNav {
  margin-top: 2%;
  padding: 4%;
  border-radius: 10%;
} */

.bottom0 {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.croix {
  padding-left: 2%;
  padding-top: 2%;
}
.absolutePhoto {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.absolutePhoto img {
  width: 650px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}
.bigPhoto {
  width: 110%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
}

.pickPhoto {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
}

.pickPhoto h1 {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 50px;
}
.pickPhotoContainer img {
  text-align: center;
}

.arrowLeft {
  position: absolute;
  top: 50%;
  left: 2%;
}
.arrowRight {
  position: absolute;
  top: 50%;
  left: 93%;
}
.arrowRightIcon {
  width: 100px;
}

.adresseAsbl {
display: flex;
justify-content: center;}

.adresseAsbl__text {
  text-align: center;
  background-color: #6a040f;
  border-radius: 10%;
  color: white;
  width: 20%;
  padding: 2%;
}

.adresseGroupes {
  display: flex;
  background-color: #6a040f;
  border-radius: 10%;
  color: white;
  width: 20%;
  margin: 50px 0px;
  flex-direction: column;
  text-align: center;
  padding: 2%;
  justify-content: center;
}
.range {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  
}

@media(max-width: 1300px) {
  .navBloc {
    margin-top : 15%
  }
  .coreInfos {
    margin-top: 150px;
  }
}


@media(max-width: 1100px) {
  .feed{
    right: 1%;
    width: 70%;
  }
  .accueil__core {
  margin-right: 10%;
  }
  .headerContainer{
    margin-top: -12px;
  }
}

@media (max-width: 900px) {
  .facebookFlux{
    display: none;
  }
  .core {
    margin-top: 220px;
  }
  .coreInfos {
    margin-top: 0px;
  }
  .adresseAsbl {
    margin: auto;
    width: 120%;

  }

  .adresseGroupes {
    width: 30%;
  }
.navBloc {
  margin-top : 3%
}

.accueil__core {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blocAccueil {
  margin-left: 50%;
}
.titreAccueil {
  margin-left: 50%;
  width: 80%;
}
  }

@media(max-width: 750px) {
  .headerContainer {
    /* margin-top: -75px; */
  }
}

